<template>
  <div :id="`panel-${containerId}-${tabIndex}`"
       :aria-labelledby="`tab-${containerId}-${tabIndex}`"
       class="bx-tabs__panel"
       :class="{ 'bx-active': isActive }"
       role="tabpanel"
       tabindex="0">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tab',
  inject: {
    TabContainer: {
      default: null
    }
  },
  props: {
    /** @public Used by parent TabContainer component */
    /* eslint-disable-next-line vue/no-unused-properties */
    title: {
      type: String,
      required: true
    },
    /** @public Used by parent TabContainer component */
    /* eslint-disable-next-line vue/no-unused-properties */
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false,
      tabIndex: 0
    }
  },
  computed: {
    containerId () {
      return this.TabContainer?.containerId
    }
  },
  mounted () {
    // Register this tab with parent container
    if (this.TabContainer?.registerTab) {
      this.TabContainer.registerTab(this)
    }
  },
  beforeUnmount () {
    // Unregister from parent when destroyed
    if (this.TabContainer?.unregisterTab) {
      this.TabContainer.unregisterTab(this)
    }
  }
}
</script>
