<template>
  <div>
    <div>
      <button @click="activeTab = 'trailer'">
        TRAILER
      </button>
      <button @click="activeTab = 'bilder'">
        BILDER
      </button>
    </div>

    <div v-if="activeTab === 'trailer'">
      <div v-for="(item, index) in trailerItems"
           :key="index">
        {{ item.embed.raw }}
      </div>
    </div>

    <div v-if="activeTab === 'bilder'">
      <div v-for="(item, index) in tmdbImageItems"
           :key="index">
        {{ item.tmdbImage.filePath }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphTypeVodSlider',
  props: {
    trailerItems: {
      type: Array,
      default: () => []
    },
    tmdbImageItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeTab: 'trailer'
    }
  }
}
</script>
