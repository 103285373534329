<template>
  <h3 class="bx-content__vod-rating__heading">
    Bewertung
  </h3>
  <div class="bx-content__vod-rating">
    <img src="/images/tvmovie/tmdb-logo-vod-rating.svg"
         alt="TMDB Logo"
         class="tmdb-logo">
    <span class="bx-content__vod-rating__text bx-typo--content-vod-rating-text">
      <span class="bx-content__vod-rating__text__value bx-typo--content-vod-rating-value">
        Bewertung: {{ value.toFixed(1).replace('.', ',') }}
      </span>
      / 10
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>
