<template>
  <div class="bx-content bx-vod-season-with-episodes bx-typo--vod-season-wth-episodes">
    <h3>{{ heading }}</h3>
    <h4>{{ seasonTitle(currentSeason.seasonNumber) }}</h4>
    <select v-model="selectedSeason"
            class="bx-form__select"
            @change="changeSeason()">
      <option v-for="i in numberOfSeasons"
              :key="i"
              :value="i">
        {{ seasonTitle(i) }}
      </option>
    </select>
    <ol>
      <li v-for="episode in currentSeason.episodes"
          :key="episode.number">
        {{ episode.title }}
        <span v-if="episode.runtime">{{ episode.runtime }} Minuten</span>
        <br>
        <span>{{ seasonTitle(currentSeason.seasonNumber) }}</span>
        &nbsp;
        <span>Folge {{ episode.number }}/{{ currentSeason.episodes.length }} ({{ numberOfEpisodes }}) </span>
        <p v-if="episode.description">
          {{ episode.description }}
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'

export default {
  props: {
    seriesCapiId: { type: String, required: true },
    seriesTitle: { type: String, required: true },
    seasonNumber: { type: Number, required: true },
    numberOfSeasons: { type: Number, required: true },
    numberOfEpisodes: { type: Number, required: true },
    episodes: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      selectedSeason: this.seasonNumber, // season picker
      currentSeason: { // defaults on initial load
        seasonNumber: this.seasonNumber,
        episodes: this.episodes
      }
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    heading () {
      return 'Die neuesten Episoden von ' + this.seriesTitle
    }
  },
  methods: {
    seasonTitle (seasonNumber = 1) {
      return 'Staffel ' + seasonNumber
    },
    async changeSeason () {
      const url = `${this.rsConfig.vodServiceUrl}/${this.seriesCapiId}/season/${this.selectedSeason}/with-episodes`
      const { data: { episodes } } = await axios.get(url) || {}

      console.log('changeSeason():episodes', episodes)

      // TODO loading indicator?

      if (episodes?.length) {
        this.currentSeason = {
          seasonNumber: this.selectedSeason,
          episodes
        }
      }
    }
  }
}
</script>
